import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlockConfig } from 'components/editor/GridDndEditor/models/BlockConfig.model';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { TableColumnType, TableRowType, TableType } from './table.types';

import { RootState } from './Store';
import { Section } from '../../Sections/Section.model';
import {
  AddSectionType,
  UpdateSectionTitleType,
  BlockDimensionType,
  BlockLayerConfigType,
  BlockPositionType,
  CurrentManipulatedBlockType,
  DeleteBlockStateProps,
  DeleteSectionType,
  MaxHeightPageType,
  TableBlockStateType,
  UpdateBlockContentType,
} from './editorSlice.types';

import { Draft } from 'immer';

type Collection<T> = {
  [key: string]: T;
};

export type BlockContent = {
  content: string;
  contentTable?: TableType;
  blockConfig: BlockConfig;
  type: GridBlockType;
};

export type BlockMetadata = {
  id: string;
  type: GridBlockType;
  imageAspectRatioLock: boolean;
};

export type BlocksContentCollection = Collection<BlockContent>;
export type SectionCollection = Collection<Section>;

export type BlocksMetadataCollection = BlockMetadata[];

export type GridState = {
  sections: SectionCollection;
};

const initialState: GridState = {
  sections: {},
};

export interface BlockAddType {
  sectionId: string;
  blockId: string;
  content: any;
  blockConfig: BlockConfig;
  blockType: GridBlockType;
}

const gridBlockSlice = createSlice({
  name: 'editor-grid-block',
  initialState,
  reducers: {
    addGridBlockState(state, action: PayloadAction<BlockAddType>) {
      const { sectionId, blockId, content, blockConfig, blockType } = action.payload;
      const section = state.sections[sectionId];
      if (!section) {
        throw new Error('Section cannot be identified.');
      }

      section.blocksMetadata.push({ id: blockId, type: blockType, imageAspectRatioLock: true });

      const textImageBlockConfig = {
        blockConfig,
        type: blockType,
        content,
      };

      const tableBlockConfig = {
        ...textImageBlockConfig,
        content: '',
        contentTable: content,
      };

      section.blocksContent[blockId] = blockType === 'TABLE' ? tableBlockConfig : textImageBlockConfig;
      section.blocksLayer.greaterZIndexAvailable++;
      return state;
    },
    updateGridBlockState(state, action: PayloadAction<UpdateBlockContentType>) {
      const { sectionId, blockId, content } = action.payload;
      const block = state.sections[sectionId]?.blocksContent[blockId];
      if (block) {
        block.content = content;
      }
    },
    updateGridTableBlockState(state, action: PayloadAction<TableBlockStateType>) {
      const { sectionId, blockId, contentTable } = action.payload;
      const block = state.sections[sectionId]?.blocksContent[blockId];
      if (block) {
        block.contentTable = contentTable;
      }
    },
    setMaxHeightPage(state, action: PayloadAction<MaxHeightPageType>) {
      const { maxHeightPage, sectionId } = action.payload;
      const section = state.sections[sectionId];
      if (section) {
        section.editorConfig.maxHeightPage = maxHeightPage;
      }
    },
    updateGridPositionConfig(state, action: PayloadAction<BlockPositionType>) {
      const { sectionId, blockId, x, y } = action.payload;
      const blockConfig = state.sections[sectionId]?.blocksContent[blockId]?.blockConfig;
      if (blockConfig) {
        blockConfig.x = x;
        blockConfig.y = y;
      }
    },
    updateGridLayerConfig(state, action: PayloadAction<BlockLayerConfigType>) {
      const { sectionId, blockId, zIndex } = action.payload;
      const section = state.sections[sectionId];
      const blockConfig = section?.blocksContent[blockId]?.blockConfig;
      if (blockConfig) {
        blockConfig.z = zIndex;

        const isBlockMovedToFrontLayer = zIndex === section.blocksLayer.greaterZIndexAvailable;
        const isBlockMovedToBackLayer = zIndex === section.blocksLayer.lowerZIndexAvailable;

        if (isBlockMovedToFrontLayer) {
          section.blocksLayer.greaterZIndexAvailable++;
        } else if (isBlockMovedToBackLayer) {
          section.blocksLayer.lowerZIndexAvailable--;
        }
      }
    },
    updateGridDimensionConfig(state, action: PayloadAction<BlockDimensionType>) {
      const { sectionId, blockId, width, height } = action.payload;
      const blockConfig = state.sections[sectionId]?.blocksContent[blockId]?.blockConfig;
      if (blockConfig) {
        blockConfig.width = width;
        blockConfig.height = height;
      }
    },
    updateCurrentBlockForGuideLines(state, action: PayloadAction<CurrentManipulatedBlockType>) {
      const { currentManipulatedBlock, sectionId } = action.payload;
      const section = state.sections[sectionId];
      if (section) {
        section.editorConfig.currentManipulatedBlock = currentManipulatedBlock;
      }
    },
    deleteGridBlockState(state, action: PayloadAction<DeleteBlockStateProps>) {
      const { sectionId, blockId } = action.payload;
      const section = state.sections[sectionId];
      if (!section) return;

      const blockIndex = section.blocksMetadata.findIndex((blockMetadata) => blockMetadata.id === blockId);

      const isBlockInFrontLayer = section.blocksContent[blockId]?.blockConfig.z === section.blocksLayer.greaterZIndexAvailable - 1;
      const isBlockInBackLayer = section.blocksContent[blockId]?.blockConfig.z === section.blocksLayer.lowerZIndexAvailable + 1;

      if (isBlockInFrontLayer) {
        section.blocksLayer.greaterZIndexAvailable = section.blocksContent[blockId].blockConfig.z;
      } else if (isBlockInBackLayer) {
        section.blocksLayer.lowerZIndexAvailable = section.blocksContent[blockId].blockConfig.z;
      }

      section.blocksMetadata.splice(blockIndex, 1);
      delete section.blocksContent[blockId];
    },
    resetState(state) {
      state.sections = {};
    },
    setInitialState(state, action: PayloadAction<GridState>) {
      const { sections } = action.payload;
      if (sections && typeof sections === 'object') {
        state.sections = {};
        Object.keys(sections).forEach((sectionId) => {
          const section = sections[sectionId];
          state.sections[sectionId] = {
            id: section.id,
            title: section.title,
            order: section.order,
            blocksContent: (section.blocksContent as Draft<BlocksContentCollection>) || {},
            blocksLayer: section.blocksLayer || { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 },
            blocksMetadata: section.blocksMetadata || [],
            editorConfig: section.editorConfig || { currentManipulatedBlock: null, maxHeightPage: 0 },
          };
        });
      } else {
        state.sections = {};
      }
    },
    updateSectionTitle(state, action: PayloadAction<UpdateSectionTitleType>) {
      const { sectionId, sectionTitle } = action.payload;
      state.sections[sectionId].title = sectionTitle;
    },
    addSection(state, action: PayloadAction<AddSectionType>) {
      const { sectionId, order, title } = action.payload;

      // Increment the order of remaining sections before adding
      // a new section in between the sections.
      Object.keys(state.sections).forEach((id) => {
        if (state.sections[id].order >= order) {
          state.sections[id].order += 1;
        }
      });

      if (!state.sections[sectionId]) {
        state.sections[sectionId] = {
          id: sectionId,
          order: order,
          title: title,
          blocksContent: {},
          blocksLayer: { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 },
          blocksMetadata: [],
          editorConfig: { currentManipulatedBlock: null, maxHeightPage: 0 },
        };
      }
    },
    deleteSection(state, action: PayloadAction<DeleteSectionType>) {
      const { sectionId } = action.payload;
      delete state.sections[sectionId];
    },
    setSelectedRow(state, action: PayloadAction<{ sectionId: string; blockId: string; row: TableRowType | null }>) {
      const { sectionId, blockId, row } = action.payload;
      const block = state.sections[sectionId]?.blocksContent[blockId];
      if (block.contentTable) {
        block.contentTable.selectedRow = row;
      }
    },
  },
});

export const selectBlockContent = (state: RootState, sectionId: string, blockId: string): BlockContent | undefined => {
  return state.gridBlockReducer.sections[sectionId]?.blocksContent[blockId];
};

export const selectContentTable = (state: RootState, sectionId: string, blockId: string) =>
  selectBlockContent(state, sectionId, blockId)?.contentTable as TableType;

export const selectContentTableRows = (state: RootState, sectionId: string, blockId: string) =>
  selectContentTable(state, sectionId, blockId)?.rows as TableRowType[];

export const selectContentTableColumns = (state: RootState, sectionId: string, blockId: string) =>
  selectContentTable(state, sectionId, blockId)?.columns as TableColumnType[];

export const selectTableTotalRows = (state: RootState, sectionId: string, blockId: string) =>
  selectContentTable(state, sectionId, blockId)?.totalRows as TableRowType[];

export const selectTotalSelectedRow = (state: RootState, sectionId: string, blockId: string) =>
  selectContentTable(state, sectionId, blockId)?.selectedRow as TableRowType;

export const selectSectionIdByBlockId = (state: RootState, blockId: string): string | undefined => {
  const sections = state.gridBlockReducer.sections;
  for (const sectionId in sections) {
    if (blockId in sections[sectionId].blocksContent) {
      return sectionId;
    }
  }
  return undefined;
};

export const {
  addGridBlockState,
  updateGridTableBlockState,
  updateGridBlockState,
  updateGridPositionConfig,
  updateGridLayerConfig,
  updateGridDimensionConfig,
  updateCurrentBlockForGuideLines,
  deleteGridBlockState,
  setMaxHeightPage,
  resetState,
  setInitialState,
  updateSectionTitle,
  addSection,
  deleteSection,
  setSelectedRow,
} = gridBlockSlice.actions;

export const gridBlockReducer = gridBlockSlice.reducer;
