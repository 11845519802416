import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        fontFamily: 'Gellix',
      },
    },
  },
};
